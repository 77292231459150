import { BffErrorResponse, useMutation } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { DELETE_FILE_FROM_CLOUD } from './delete-file-from-cloud.mutation';
import { DeleteFileFromCloudResponse } from './delete-file-from-cloud.types';

export function useDeleteFileFromCloud(): DeleteFileFromCloudResponse {
  const [deleteFileFromCloud, { data, loading, error }] = useMutation<
    Pick<Mutation, 'deleteFileFromCloud'>
  >(DELETE_FILE_FROM_CLOUD, {
    onCompleted: (content) => {
      hermesLogger.info(
        '[DeleteFileFromCloud] File deleted successfully',
        content
      );
    },

    onError: (error) => {
      hermesLogger.error(
        '[DeleteFileFromCloud] Error when trying to delete file from cloud',
        {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        }
      );
    },
  });

  async function handleDeleteFileFromCloud(scope: string) {
    const response = await deleteFileFromCloud({ variables: { scope } });

    if (response.errors) {
      throw error;
    }

    return response;
  }

  return {
    actions: {
      handleDeleteFileFromCloud,
    },
    data: data?.deleteFileFromCloud,
    loading,
    error: error?.graphQLErrors[0]?.extensions as BffErrorResponse,
  };
}
