import { useUploadFileStore } from './use-upload-file.store';

export function useUploadFileState() {
  const uploadedFile = useUploadFileStore((state) => state.file);
  const uploadStatus = useUploadFileStore((state) => state.status);
  const hasErrorOnUpload = useUploadFileStore((state) => state.error);
  const canProcessUpload = useUploadFileStore((state) => state.canProcess);

  return {
    uploadedFile,
    uploadStatus,
    hasErrorOnUpload,
    canProcessUpload,
  };
}

export function useUploadFileActions() {
  const setUploadedFile = useUploadFileStore((state) => state.setUploadedFile);
  const uploadOnGoing = useUploadFileStore((state) => state.uploadOnGoing);
  const uploadSuccess = useUploadFileStore((state) => state.uploadSuccess);
  const uploadFailed = useUploadFileStore((state) => state.uploadFailed);
  const removeFile = useUploadFileStore((state) => state.removeFile);

  return {
    setUploadedFile,
    uploadOnGoing,
    uploadSuccess,
    uploadFailed,
    removeFile,
  };
}
