import { useProfile } from '@common/uaa';
import { UserAccessType } from '@types';
import { useRouter } from 'next/router';
import { ComponentType, useEffect } from 'react';

type RouteAccessProps = {
  accesses: UserAccessType[];
};

export function withRouteAccess<P extends object>(
  Component: ComponentType<P>,
  { accesses }: RouteAccessProps
) {
  return function RouteAccess(props: P) {
    const router = useRouter();
    const profile = useProfile();

    useEffect(() => {
      if (profile) {
        const hasAccess =
          profile?.accessType === UserAccessType.Admin ||
          accesses.some((access) => profile.accessType.includes(access));

        if (!hasAccess) {
          router.replace('/404');
        }
      }
    }, [profile, router]);

    return <Component {...props} />;
  };
}
