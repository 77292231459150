import { ReservakiFile } from '@types';
import { create } from 'zustand';

type UploadFileState = {
  file?: File | ReservakiFile;
  status?: 'processing' | 'success' | 'exceededSize' | 'failed';
  error?: 'exceededSize' | 'failed';
  canProcess: boolean;
};

const initialUploadState: UploadFileState = {
  file: undefined,
  status: undefined,
  error: undefined,
  canProcess: false,
};

type UploadFileStore = UploadFileState & {
  setUploadedFile: (file: File | ReservakiFile) => void;
  uploadOnGoing: (file?: File | ReservakiFile) => void;
  uploadSuccess: (file: File | ReservakiFile) => void;
  uploadFailed: (
    error: 'exceededSize' | 'failed',
    file?: File | ReservakiFile
  ) => void;
  removeFile: () => void;
};

export const useUploadFileStore = create<UploadFileStore>((set) => ({
  ...initialUploadState,
  setUploadedFile: (file: File | ReservakiFile) => set({ file }),
  uploadOnGoing: (file) =>
    set({ file, status: 'processing', canProcess: false }),
  uploadSuccess: (file) => set({ file, status: 'success', canProcess: true }),
  uploadFailed: (error, file) =>
    set({ file, status: error, error, canProcess: false }),
  removeFile: () => set(initialUploadState),
}));
