import { BffErrorResponse, useQuery } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Query, Subscription } from '@types';
import { useEffect } from 'react';

import { GET_FILE_FROM_CLOUD } from './get-file-from-cloud.query';
import { GET_FILE_FROM_CLOUD_SUBSCRIPTION } from './get-file-from-cloud.subscription';
import {
  GetFileFromCloudArgs,
  GetFileFromCloudResponse,
} from './get-file-from-cloud.types';

export function useGetFileFromCloud({
  scope,
}: GetFileFromCloudArgs): GetFileFromCloudResponse {
  const { data, loading, error, subscribeToMore } = useQuery<
    Pick<Query, 'fileFromCloud'>
  >(GET_FILE_FROM_CLOUD, {
    variables: { scope },
    fetchPolicy: 'network-only',
    onCompleted: (content) => {
      hermesLogger.info(
        '[GetFileFromCloud] File retrieved successfully',
        content
      );
    },
    onError: (error) => {
      hermesLogger.error(
        '[GetFileFromCloud] Error when trying to retrieve file from cloud',
        {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        }
      );
    },
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<Pick<Subscription, 'fileFromCloud'>>({
      document: GET_FILE_FROM_CLOUD_SUBSCRIPTION,
      variables: { scope },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const updatedFileFromCloud = subscriptionData.data.fileFromCloud?.name;

        if (!updatedFileFromCloud) {
          return {
            fileFromCloud: null,
          };
        }

        return {
          ...prev,
          fileFromCloud: {
            ...prev.fileFromCloud,
            ...subscriptionData.data.fileFromCloud,
          },
        };
      },
    });

    return () => unsubscribe();
  }, [subscribeToMore, scope]);

  return {
    data: data?.fileFromCloud,
    loading,
    error: error?.graphQLErrors[0]?.extensions as BffErrorResponse,
  };
}
