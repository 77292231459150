import { ChangeEvent, useCallback } from 'react';

import { UploadedFileCard, UploadFileInput } from './components';
import { useUploadFile } from './use-upload-file';

// 5 MB
const DEFAULT_MAX_FILE_SIZE = 5 * 1024 * 1024;

export type UploadFileProps = {
  acceptedTypes?: string;
  scopeToUpload: string;
};

export function UploadFile({
  acceptedTypes = '.csv',
  scopeToUpload,
}: UploadFileProps) {
  const {
    data: { inputRef, uploadedFile, uploadStatus, hasErrorOnUpload },
    actions: {
      setUploadedFile,
      removeFile,
      uploadFailed,
      handleUploadFileToCloud,
    },
  } = useUploadFile({ scopeToUpload });

  const handleInputClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleFileUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (!file) return;

      if (file.size > DEFAULT_MAX_FILE_SIZE) {
        return uploadFailed('exceededSize', file);
      }

      setUploadedFile(file);

      handleUploadFileToCloud(
        scopeToUpload,
        file.type,
        await handleConvertFileToBase64(file),
        file.name,
        file.size
      );
    },
    [setUploadedFile, handleUploadFileToCloud, scopeToUpload, uploadFailed]
  );

  async function handleConvertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = function () {
        const result = fileReader.result as string;
        const base64 = result.split(',')[1];

        resolve(base64);
      };

      fileReader.onerror = function () {
        reject(fileReader.result);
      };
    });
  }

  if (!uploadedFile && !uploadStatus && !hasErrorOnUpload) {
    return (
      <UploadFileInput
        ref={inputRef}
        handleInputClick={handleInputClick}
        handleFileUpload={handleFileUpload}
        acceptedTypes={acceptedTypes}
      />
    );
  }

  return (
    <UploadedFileCard
      type={uploadStatus}
      uploadedFile={uploadedFile}
      handleRemoveFile={removeFile}
    />
  );
}
