import { gql } from '@common/bff';

export const UPLOAD_FILE_TO_CLOUD = gql`
  mutation Global_UseCase_UploadFileToCloud(
    $scope: String!
    $contentType: String!
    $fileBase64: String!
    $fileName: String!
    $fileSize: Int!
  ) {
    uploadFileToCloud(
      scope: $scope
      contentType: $contentType
      fileBase64: $fileBase64
      fileName: $fileName
      size: $fileSize
    ) {
      scope
    }
  }
`;
