import { useMutation } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { UPLOAD_FILE_TO_CLOUD } from './upload-file-to-cloud.mutation';
import { UploadFileToCloudResponse } from './use-upload-file-to-cloud.types';

export function useUploadFileToCloud(): UploadFileToCloudResponse {
  const [uploadFileToCloud, { data, loading, error }] = useMutation<
    Pick<Mutation, 'uploadFileToCloud'>
  >(UPLOAD_FILE_TO_CLOUD, {
    onCompleted: (content) => {
      hermesLogger.info(
        '[UploadFileToCloud] File uploaded successfully',
        content
      );
    },

    onError: (error) => {
      hermesLogger.error(
        '[UploadFileToCloud] Error when trying to upload file to cloud',
        {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        }
      );
    },
  });

  async function handleUploadFileToCloud(
    scope: string,
    contentType: string,
    fileBase64: string,
    fileName: string,
    fileSize: number
  ) {
    return uploadFileToCloud({
      variables: { scope, contentType, fileBase64, fileName, fileSize },
    });
  }

  return {
    actions: {
      handleUploadFileToCloud,
    },
    data: data?.uploadFileToCloud,
    loading,
    error,
  };
}
