import { useIntl } from '@common/i18n';
import { Flex, Input, Text } from '@common/ui-components';
import {
  ChangeEvent,
  DragEvent,
  forwardRef,
  MutableRefObject,
  useState,
} from 'react';
import { IoCloudUploadOutline } from 'react-icons/io5';

import { uploadFileIntlIds } from '../../intl';

export type UploadedFileInputProps = {
  ref?: MutableRefObject<HTMLInputElement | null>;
  acceptedTypes?: string;
  handleInputClick: () => void;
  handleFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UploadFileInput = forwardRef<
  HTMLInputElement,
  UploadedFileInputProps
>(({ acceptedTypes, handleInputClick, handleFileUpload }, ref) => {
  const { formatMessage } = useIntl();

  const [isDragging, setIsDragging] = useState(false);

  const inputRef = ref as MutableRefObject<HTMLInputElement | null>;

  function handleDragFileOver(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDropFile(event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files.length > 0) {
      const fileInput = inputRef?.current;

      if (fileInput) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(event.dataTransfer.files[0]);
        fileInput.files = dataTransfer.files;

        fileInput.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="1px dashed"
      borderColor={isDragging ? 'blue.500' : 'gray.300'}
      borderRadius={8}
      color="blue.500"
      bg="gray.50"
      cursor="pointer"
      padding={5}
      gap={2}
      onClick={handleInputClick}
      onDragOver={handleDragFileOver}
      onDragLeave={() => setIsDragging(false)}
      onDrop={handleDropFile}
      _hover={{ borderColor: 'blue.500' }}
    >
      <IoCloudUploadOutline size={32} />

      <Text fontWeight="bold" color="blue.500">
        {formatMessage({ id: uploadFileIntlIds.label })}
      </Text>

      <Text fontSize="sm" color="gray.500">
        {formatMessage({ id: uploadFileIntlIds.maxSize })}
      </Text>

      <Input
        ref={inputRef}
        aria-label={formatMessage({ id: uploadFileIntlIds.label })}
        type="file"
        onChange={handleFileUpload}
        accept={acceptedTypes}
        style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}
      />
    </Flex>
  );
});

UploadFileInput.displayName = 'UploadFileInput';
