import { gql } from '@common/bff';

export const GET_FILE_FROM_CLOUD_SUBSCRIPTION = gql`
  subscription Global_UseCase_GetFileFromCloud($scope: String!) {
    fileFromCloud(scope: $scope) {
      name
      size
    }
  }
`;
