import { useIntl } from '@common/i18n';
import { Button, Circle, Flex, Spinner, Text } from '@common/ui-components';
import { ReservakiFile } from '@types';
import { useMemo } from 'react';
import { BiErrorCircle } from 'react-icons/bi';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { MdOutlineDelete } from 'react-icons/md';

import { uploadFileIntlIds } from '../../intl';

export type UploadedFileCardProps = {
  type?: 'processing' | 'success' | 'exceededSize' | 'failed';
  uploadedFile?: File | ReservakiFile;
  handleRemoveFile: () => void;
};

export function UploadedFileCard({
  type = 'processing',
  uploadedFile,
  handleRemoveFile,
}: UploadedFileCardProps) {
  const { formatMessage } = useIntl();

  const props = useMemo(() => {
    return {
      processing: {
        color: 'blue.500',
        bg: 'blue.100',
        icon: <Spinner thickness="2px" speed="0.7s" color="blue.500" />,
        label: formatMessage({
          id: uploadFileIntlIds.onUpload.processing.label,
        }),
      },
      success: {
        color: 'green.500',
        bg: 'green.100',
        icon: <IoCheckmarkCircleOutline size={24} />,
        label: formatMessage({ id: uploadFileIntlIds.onUpload.success.label }),
      },
      exceededSize: {
        color: 'red.500',
        bg: 'red.100',
        icon: <BiErrorCircle size={24} />,
        label: formatMessage({
          id: uploadFileIntlIds.onUpload.exceededSize.label,
        }),
        errorMessage: formatMessage({
          id: uploadFileIntlIds.onUpload.exceededSize.error.label,
        }),
      },
      failed: {
        color: 'red.500',
        bg: 'red.100',
        icon: <BiErrorCircle size={24} />,
        label: formatMessage({ id: uploadFileIntlIds.onUpload.failed.label }),
        errorMessage: formatMessage({
          id: uploadFileIntlIds.onUpload.failed.error.label,
        }),
      },
    }[type];
  }, [type, formatMessage]);

  const hasErrorMessage = type !== 'processing' && type !== 'success';

  return (
    <Flex flexDirection="column" gap={2}>
      <Flex
        alignItems="center"
        borderWidth={1}
        borderColor="gray.300"
        borderRadius={8}
        padding={2}
        gap={2}
      >
        <Circle size={10} color={props.color} bg={props.bg}>
          {props.icon}
        </Circle>

        <Flex flex={1} flexDirection="column">
          <Text fontWeight="bold" color={props.color}>
            {props.label}
          </Text>

          {uploadedFile && (
            <Flex justifyContent="space-between">
              <Text fontSize="sm" color="gray.500">
                {uploadedFile?.name}
              </Text>

              <Text fontSize="xs" color="gray.500">
                {(Number(uploadedFile?.size) / 1024).toFixed(0)}KB
              </Text>
            </Flex>
          )}
        </Flex>

        <Button
          onClick={handleRemoveFile}
          variant="ghost"
          size="sm"
          color="gray.800"
          _hover={{ backgroundColor: 'gray.100' }}
        >
          <MdOutlineDelete
            title={formatMessage({
              id: uploadFileIntlIds.onUpload.removeFile.label,
            })}
            size={20}
          />
        </Button>
      </Flex>

      {hasErrorMessage && (
        <Flex alignItems="center" color={props.color} gap={2}>
          <BiErrorCircle size={16} />

          <Text fontSize="sm" color="inherit">
            {props.errorMessage}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
