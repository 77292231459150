import {
  useUploadFileActions,
  useUploadFileState,
} from '@common/core-components';
import {
  useDeleteFileFromCloud,
  useGetFileFromCloud,
  useUploadFileToCloud,
} from '@use-cases';
import { useCallback, useEffect, useRef } from 'react';

type UseUploadFileArgs = {
  scopeToUpload: string;
};

export function useUploadFile({ scopeToUpload }: UseUploadFileArgs) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { uploadedFile, uploadStatus, hasErrorOnUpload } = useUploadFileState();
  const {
    setUploadedFile,
    uploadOnGoing,
    uploadSuccess,
    uploadFailed,
    removeFile,
  } = useUploadFileActions();

  const {
    data: fileLoadedFromCloud,
    loading: isGetFileFromCloudLoading,
    error: errorOnGetFileFromCloud,
  } = useGetFileFromCloud({ scope: scopeToUpload });

  const {
    data: uploadFileToCloud,
    loading: isUploadFileToCloudLoading,
    error: errorOnUploadFileToCloud,
    actions: { handleUploadFileToCloud },
  } = useUploadFileToCloud();

  const {
    actions: { handleDeleteFileFromCloud },
  } = useDeleteFileFromCloud();

  useEffect(() => {
    if (isGetFileFromCloudLoading) {
      return uploadOnGoing();
    }
  }, [isGetFileFromCloudLoading, uploadOnGoing]);

  useEffect(() => {
    if (errorOnGetFileFromCloud) {
      return uploadFailed('failed');
    }
  }, [errorOnGetFileFromCloud, uploadFailed]);

  useEffect(() => {
    if (fileLoadedFromCloud) {
      return uploadSuccess(fileLoadedFromCloud);
    }
  }, [fileLoadedFromCloud, uploadSuccess]);

  useEffect(() => {
    if (uploadedFile && isUploadFileToCloudLoading) {
      return uploadOnGoing(uploadedFile);
    }
  }, [uploadedFile, isUploadFileToCloudLoading, uploadOnGoing]);

  useEffect(() => {
    if (uploadedFile && errorOnUploadFileToCloud) {
      return uploadFailed('failed', uploadedFile);
    }
  }, [uploadedFile, errorOnUploadFileToCloud, uploadFailed]);

  useEffect(() => {
    if (
      uploadedFile &&
      uploadFileToCloud &&
      hasErrorOnUpload !== 'exceededSize'
    ) {
      return uploadSuccess(uploadedFile);
    }
  }, [uploadedFile, uploadFileToCloud, hasErrorOnUpload, uploadSuccess]);

  useEffect(() => {
    if (
      !fileLoadedFromCloud &&
      !isGetFileFromCloudLoading &&
      !errorOnGetFileFromCloud
    ) {
      removeFile();
    }
  }, [
    fileLoadedFromCloud,
    isGetFileFromCloudLoading,
    errorOnGetFileFromCloud,
    removeFile,
  ]);

  const handleRemoveFile = useCallback(async () => {
    try {
      await handleDeleteFileFromCloud(scopeToUpload);

      if (inputRef.current) {
        inputRef.current.value = '';
      }

      removeFile();
    } catch (error) {
      return;
    }
  }, [handleDeleteFileFromCloud, scopeToUpload, removeFile]);

  return {
    data: {
      inputRef,
      uploadedFile,
      hasErrorOnUpload,
      uploadStatus,
      fileLoadedFromCloud,
      uploadFileToCloud,
    },
    actions: {
      setUploadedFile,
      removeFile: handleRemoveFile,
      uploadFailed,
      handleUploadFileToCloud,
    },
  };
}
